@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	padding: 0;
	margin: 0;
	background-color: var(--background);
	min-height: 100vh;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

h1,
h2 {
	color: var(--black) !important;
}

:root {
	--black: rgba(42, 42, 43);
	--red: rgb(70, 70, 70);
	--green: rgb(85, 128, 0);
	--blue: #007aff;
	--darkgray: #6e6e73;
	--background: #f1f1f1;
	--white: rgb(255, 255, 255);
}

input[type='file'],
input[type='file']::-webkit-file-upload-button {
	@apply cursor-pointer focus:outline-none;
}

input:focus {
	box-shadow: none !important;
}

.admin-form {
	@apply w-1/2 mt-12;
}

/* Slide animation */
.slide-animation-enter {
	opacity: 0;
	transform: scale(0.9);
}
.slide-animation-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition:
		opacity 300ms,
		transform 300ms;
}
.slide-animation-exit {
	opacity: 1;
}
.slide-animation-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition:
		opacity 300ms,
		transform 300ms;
}

html,
body {
	padding: 0;
	margin: 0;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica Neue,
		sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}
.ant-modal-body {
	padding: 0px !important;
	overflow: hidden;
}

.modal__bottom {
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 5px 10px;
	border-top: 0.5px solid lightgray;
	background: white;
}
.bottomsheet_heading {
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: 0 8px;
	align-items: center;
}
.bottomsheet_heading--icon {
	position: fixed;
	right: 20px;
	margin: 0 auto;
	font-size: 22px !important;
	cursor: pointer;
}

.confirmation__modal--body {
	padding: 14px;
}

.content {
	padding: 8px 20px 50px 20px;
}

select {
	border: 1px solid var(--black);
	border-radius: 10px;
	padding: 5px 10px;
	min-width: 200px;
	text-overflow: ellipsis;
	overflow: hidden;
}

select::placeholder {
	font-size: 18px;
}

option {
	background-color: var(--white);
}

.custom-select__value-container {
	height: 50px;
	overflow: auto !important;
}

.navigation-wrapper {
	position: relative;
}

.dots {
	display: flex;
	padding: 10px 0;
	justify-content: center;
}

.dot {
	border: none;
	width: 10px;
	height: 10px;
	background: #c5c5c5;
	border-radius: 50%;
	margin: 0 5px;
	padding: 5px;
	cursor: pointer;
}

.dot:focus {
	outline: none;
}

.dot.active {
	background: #000;
}

.arrow {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	fill: #000;
	cursor: pointer;
}

.arrow--left {
	left: 0px;
}

.arrow--right {
	right: 0px;
}

.arrow--disabled {
	fill: rgba(0, 0, 0, 0.5);
}
.ReactModal__Body--open {
	overflow: hidden;
}

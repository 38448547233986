.loader {
	@apply flex justify-center items-center w-full h-full;
	z-index: 1059;
}

.center {
	@apply fixed top-0 bottom-0 left-0 right-0;
}

.ldsRing {
	@apply inline-block relative w-[80px] h-[80px];
}
.ldsRing div {
	@apply box-border block absolute w-[64px] h-[64px] m-2 border-solid border-8 border-black rounded-full;
	animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: black transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
	animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
	animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes ldsRing {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.login {
	background: #f2f5fa;
}

.smallloader {
	@apply flex justify-center;
}

.smallldsRing {
	@apply inline-block relative w-[80px] h-[80px];
}

.smallldsRing div {
	animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}

.smallldsRing div:after {
	@apply block absolute w-[7px] h-[7px] rounded-full bg-black;
	content: ' ';
	margin: -4px 0 0 -4px;
}

.smallldsRing div:nth-child(1) {
	animation-delay: -0.036s;
}

.smallldsRing div:nth-child(1):after {
	top: 63px;
	left: 63px;
}

.smallldsRing div:nth-child(2) {
	animation-delay: -0.072s;
}

.smallldsRing div:nth-child(2):after {
	top: 68px;
	left: 56px;
}

.smallldsRing div:nth-child(3) {
	animation-delay: -0.108s;
}

.smallldsRing div:nth-child(3):after {
	top: 71px;
	left: 48px;
}

.smallldsRing div:nth-child(4) {
	animation-delay: -0.144s;
}

.smallldsRing div:nth-child(4):after {
	top: 72px;
	left: 40px;
}

.smallldsRing div:nth-child(5) {
	animation-delay: -0.18s;
}

.smallldsRing div:nth-child(5):after {
	top: 71px;
	left: 32px;
}

.smallldsRing div:nth-child(6) {
	animation-delay: -0.216s;
}

.smallldsRing div:nth-child(6):after {
	top: 68px;
	left: 24px;
}

.smallldsRing div:nth-child(7) {
	animation-delay: -0.252s;
}

.smallldsRing div:nth-child(7):after {
	top: 63px;
	left: 17px;
}

.smallldsRing div:nth-child(8) {
	animation-delay: -0.288s;
}

.smallldsRing div:nth-child(8):after {
	top: 56px;
	left: 12px;
}

@keyframes smallldsRing {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.filter {
	@apply flex flex-col bg-white mb-4 p-3 rounded-xl;
	max-height: 250px;

	.title {
		@apply mb-2;
	}

	&.choice {
		.content {
			@apply overflow-auto flex flex-col;

			.item {
				@apply flex justify-between items-center pt-2;

				label {
					@apply text-sm w-[90%];
				}

				input {
					@apply flex;
				}

				&:first-child {
					@apply pt-0;
				}

				&:last-child {
					@apply mb-0;
				}
			}
		}
	}

	&.price {
		.content {
			@apply flex flex-col;

			label {
				@apply mb-3 flex items-center gap-2;
			}

			input {
				@apply w-full;
			}
		}
	}
}

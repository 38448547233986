.common {
	> label {
		@apply block;

		> span {
			@apply text-xs uppercase text-gray-600 block;
		}
	}
}

.field {
	@apply text-left mb-2;

	input {
		width: 100%;

		&:disabled {
			color: hsl(0, 0%, 60%);
			background-color: hsl(0, 0%, 95%);
			border-color: #999aa5;
		}
	}

	&.error {
		input {
			border-color: red;
		}

		> label > p {
			@apply text-sm w-full self-center mx-auto text-red-600;
		}
	}
}

.required {
	&::before {
		content: '*';
		color: red;
	}
}

.App {
	font-family: sans-serif;
	text-align: center;
}

/* css for slider-container */
.slider-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px;
	margin-bottom: 16px;
}
/* css for input-range */
input[type='range'] {
	position: absolute;
	pointer-events: none;
	outline: none;
	height: 0;
	width: 220px;
}
.input-range-left {
	z-index: 30;
}
.input-range-right {
	z-index: 40;
}
/* css for input-slider-thumb */
/* Removing the default appearance */
.input-slider-thumb,
.input-slider-thumb::-webkit-slider-thumb {
	-webkit-appearance: none;
	-webkit-tap-highlight-color: transparent;
}

/* For Chrome browsers */
.input-slider-thumb::-webkit-slider-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

/* For Firefox browsers */
.input-slider-thumb::-moz-range-thumb {
	background-color: #f1f5f7;
	border: none;
	border-radius: 50%;
	box-shadow: 0 0 1px 1px #ced4da;
	cursor: pointer;
	height: 18px;
	width: 18px;
	margin-top: 4px;
	pointer-events: all;
	position: relative;
}

/* css for designing the slider */
.slider-track-container {
	position: relative;
	width: 220px;
}
.slider-track {
	position: absolute;
	width: 100%;
	height: 2px;
	background: #aaa;
}
.slider-track-value {
	position: absolute;
	border-radius: 50px;
	height: 15px;
	z-index: 20;
}
.slider-track-indicator {
	position: absolute;
	margin-top: 16px;
	font-size: 14px;
}

.slider-track-indicator-left {
	left: -10px;
}

.slider-track-indicator-right {
	right: -10px;
}

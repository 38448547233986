.inputWrapper {
	@apply relative;
}

.input {
	@apply border border-solid py-1 px-2 bg-white rounded-md text-lg;
	border-color: #ccc;

	&::placeholder {
		@apply text-lg;
	}
	&:active {
		@apply border-primary;
	}
}

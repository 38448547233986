.basket{
	position: relative;
	.info{
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: red;
		color: #fff;
		position: absolute;
		top: -3px;
		right: -3px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px;
	}
}
.header {
	background-color: var(--black);
	box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);

	.menuIcon {
		@apply cursor-pointer inline-block float-right relative py-5 px-7 select-none;

		.navicon {
			@apply bg-white block relative;
			transition: background 0.2s ease-out;
			height: 2px;
			width: 18px;

			&::before {
				top: 5px;
			}

			&::after {
				top: -5px;
			}

			&::before,
			&::after {
				@apply bg-white block absolute w-full h-full;
				content: '';
				transition: all 0.2s ease-out;
			}
		}
	}

	.menu {
		@apply flex flex-col items-center max-h-0 overflow-hidden m-auto list-none clear-both;
		transition: max-height 0.2s ease-out;
		background-color: var(--black);
		max-width: 1920px;

		div {
			@apply flex flex-row my-2;

			a {
				padding: 10px;
				color: var(--white);
			}
		}

		.search {
			height: 30px;

			button {
				@apply px-1 py-0 ml-2;
			}
		}
	}

	.menuBtn {
		display: none;

		&:checked ~ .menu {
			max-height: 240px;
		}

		&:checked ~ .menu-icon .navicon {
			background: transparent;
		}

		&:checked ~ .menu-icon .navicon:before {
			transform: rotate(-45deg);
		}

		&:checked ~ .menu-icon .navicon:after {
			transform: rotate(45deg);
		}

		&:checked ~ .menu-icon .navicon:after {
			transform: rotate(45deg);
		}

		&:checked ~ .menu-icon:not(.steps) .navicon:before,
		&:checked ~ .menu-icon:not(.steps) .navicon:after {
			top: 0;
		}
	}
}

@media (min-width: 48em) {
	.header li {
		float: left;
	}
	.header .menu {
		@apply flex-row justify-around;
		clear: none;
		max-height: none;
	}
	.header li a {
		padding: 20px 30px;
	}
	.header .menuIcon {
		display: none;
	}
}

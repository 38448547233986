.button {
	@apply border p-1.5 rounded-lg;

	&:hover {
		@apply opacity-60;
	}

	&.white {
		@apply bg-white text-black;
	}

	&.black {
		@apply bg-black text-white;
	}

	&.blue {
		@apply w-fit bg-none text-blue;
		text-decoration: none;
	}

	&:disabled {
		@apply pointer-events-none opacity-70;
	}
}

.layout {
	@apply relative;

	.center {
		@apply p-12 pt-3;

		min-height: calc(100vh - 212px);
		height: 100%;
		max-width: 1440px;
		margin: 0 auto;
	}
}

@media (max-width: 900px) {
	.layout {
		.center {
			@apply p-5;
		}
	}
}

@media (max-width: 400px) {
	.layout {
		.center {
			@apply p-2.5;
		}
	}
}
